import React, { useEffect, useState } from 'react'
import SummaryDatatableComponent from './SummanyDatatableComponent';
import DetailsComponent from './DetailsComponent';

const BoxCutComponent = (props) => {
    const [details, setDetails] = useState(null);
    const [title, setTitle] = useState("");

    useEffect(() => {

        if(details){
            const summaryBtn = document.getElementById("nav-summary-tab");
            const detailsBtn = document.getElementById("nav-details-tab");
            detailsBtn.disabled = true;
            summaryBtn.click();
            setDetails(null);
            setTitle("");
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.filters]);

    const handleSelectDetails = (value) => {
        const btn = document.getElementById("nav-details-tab");
        setDetails(value);
        setTitle("Detalle");
        btn.disabled = false;
        btn.click();
    }

    return (
        <div className='container-full'>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button className="nav-link active" id="nav-summary-tab" data-bs-toggle="tab" data-bs-target="#nav-summary" type="button" role="tab" aria-controls="nav-summary" aria-selected="true">Resumen</button>
                <button className="nav-link" id="nav-details-tab" data-bs-toggle="tab" data-bs-target="#nav-details" type="button" role="tab" aria-controls="nav-details" aria-selected="false" disabled={process.env.NODE_ENV === "development" ? false : true}>{title}</button>
            </div>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-summary" role="tabpanel" aria-labelledby="nav-summary-tab" tabIndex="0">
                    <SummaryDatatableComponent {...props} handleSelectDetails={handleSelectDetails}/>
                </div>
                <div className="tab-pane fade details-container" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab" tabIndex="0">
                    <DetailsComponent details={details}  />
                </div>
            </div>
      </div>
    )
}

export default BoxCutComponent;
