import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import IsInvalidError from "../../../utils/IsInvalidError";
import * as yup from "yup";
import GetCurrentYear from "../../../utils/GetCurrentYear";
import { postRequestChangePassword } from "../../../services/auth/AuthService";
import ToastError from "../../../utils/ToastError";
import ToastInfo from "../../../utils/ToastInfo";

const RecoverPasswordComponent = () => {
  const navigate = useNavigate();
  const [isSend,setIsSend] = useState(false);

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object({
      email: yup
        .string("Ingrese su correo electronico.")
        .email("Ingrese un correo electrónico valido.")
        .required("Su correo electrónico es requerido."),
    }),
    onSubmit: async (values) => {
      setIsSend(true);
      await postRequestChangePassword(values).then((response)=> {
        if(response.status === 200){
          ToastInfo("Correo de recuperación enviado correctamente");
          setTimeout(()=> {
            setIsSend(false);
            navigate("/login");
          },1500);
        }
      }).catch((error)=> {
        const response = error.response;
        if(response?.status === 400){
          response?.data?.errorMessages.map((item)=> ToastError(item))
          setIsSend(false);
        }
      })
    },
  });

  const handleBack = () => navigate(-1);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        <label htmlFor="email" className="form-label text-white">
          Correo electrónico
        </label>
        <input
          type="email"
          className={IsInvalidError(formik, "email")}
          id="email"
          name="email"
          placeholder="Ingrese su correo electrónico."
          value={formik.values.email}
          onChange={formik.handleChange}
          aria-describedby="emailHelp"
          autoComplete="off"
        />
        <div className="invalid-feedback">{formik.errors.email}</div>
        <div id="emailHelp" className="form-text text-info">
          Nunca compartas tu correo con nadie.
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-primary col-12 col-md-12 col-sm-12 mb-1 fs-6"
        disabled={isSend}
      >
        {isSend ? "Enviando...": "Recuperar Contraseña"}
      </button>
      <button
        type="button"
        className="btn btn-danger text-danger col-12 col-md-12 col-sm-12 mb-3 fs-6"
        onClick={handleBack}
        disabled={isSend}
      >
        Regresar
      </button>

      <div className="d-flex justify-content-center align-content-center form-text text-white">
        <span className="text-wrap text-center">DERECHOS RESERVADOS HG Consulting &copy; {GetCurrentYear()}</span>
      </div>
    </form>
  );
};

export default RecoverPasswordComponent;
