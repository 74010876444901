import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SelectBranchComponent from './SelectBranchComponent';
import useBranch from '../../hooks/useBranch';
import DateUTCFormat from '../../utils/DateUTCFormat';


const ToolbarCutZComponent = (props) => {

  const branchs = useBranch();
  const scheduledTime = 10 * 60 * 1000;
  const [state, setState] = useState({
    branch_id: branchs[0].id,
    date: new Date()
  });


  useEffect(() => {

    const initDate = {
      ...state,
      date: DateUTCFormat(state.date, 'YYYY-MM-DDT06:00:00')
    }

    props.handleSyncData(initDate);

    window.addEventListener("scroll", scroll);

    const interval = setInterval(scheduledTask,scheduledTime);

    return () => {

      clearInterval(interval);
      window.addEventListener("scroll", scroll);
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const scroll = () => {

    const scrollY = window.scrollY;
    const toolbarContainer = document.querySelector(".toolbar-container");

    if (scrollY > 5) {
      toolbarContainer?.classList?.add('down');
    } else {
      toolbarContainer?.classList?.remove('down');
    }

  };

  const scheduledTask = () => props.handleSyncData(state);


  const handleBranchChange = ({target}) => {

    const values = {...state, [target.name]: target.value };
    props.handleSyncData(values);
    setState(values);

  }


  const handleDateChange = (date) => {

    const values = {...state, date: date };
    props.handleSyncData(values);
    setState(values);

  };

  const getBranchNameById = (id) => {
    const branch = [...branchs].find((branch) => branch.id === parseInt(id));
    return branch?.name;
  }

  return (
    <div className="toolbar">
      <div className="toolbar-container">
        <div className="d-flex flex-wrap flex-md-row flex-column mb-3 border-bottom border-2 vw-auto">
            <div className="col-12 col-md-12 col-lg-6 mb-3">
              <div className="d-flex flex-wrap gap-2 justify-content-lg-start justify-content-md-center justify-content-center">
                  <h2 className="title">{props.title}:</h2>
                  <h2 className="title italic">{getBranchNameById(state.branch_id)}</h2>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6">
              <div className="d-flex flex-column flex-md-row gap-3 justify-content-lg-end justify-content-md-center">
                <div className="mb-3">
                  <SelectBranchComponent
                    className="form-select"
                    id="branch_id"
                    name="branch_id"
                    data={branchs}
                    value={state.branch_id}
                    onChange={handleBranchChange}
                    autoComplete='off'
                    />
                </div>
                <div className="mb-3">
                  <DatePicker
                      selected={state.date}
                      onChange={handleDateChange}
                      startDate={state.date}
                      maxDate={new Date()}
                      placeholderText="Fecha"
                      className='form-control'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ToolbarCutZComponent;
