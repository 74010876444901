import ConvertArrayOfObjectsToCSV from "./ConvertArrayOfObjectsToCSV";

const  DownloadCSV = (array = null) => {

	if(typeof array !== "undefined" && array !== null){

		const link = document.createElement('a');
		let csv = ConvertArrayOfObjectsToCSV(array);
		if (csv == null) return;

		const filename = 'export.csv';

		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}

		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();

	}
}

export default DownloadCSV;
