
const RandomRGBA = (minR=50, maxR=255, minG=100, maxG=255, minB=150, maxB=255, minA=0.0, maxA=1) => {
    const r = Math.floor(Math.random() * (maxR - minR + 1) + minR);
    const g = Math.floor(Math.random() * (maxG - minG + 1) + minG);
    const b = Math.floor(Math.random() * (maxB - minB + 1) + minB);
    const a = (Math.random() * (maxA - minA) + minA).toFixed(1);
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export default RandomRGBA;
