import ToCapitalize from "./ToCapitalize";

const ToCapitalizeAll = (value) => {

 const words = value.split(' ');

 const capitalizedWords = words.map(word => {
    return word.length > 0 ? ToCapitalize(word) : '';
 });

 const capitalizeString = capitalizedWords.join(' ');

 return capitalizeString;

}

export default ToCapitalizeAll
