import React, { useEffect, useState } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { solarizedTheme } from '../shared/SolarizedTheme';
import { customStyles } from '../shared/CustomStyles';
import ExportCSVComponent from '../shared/ExportCSVComponent';
import DownloadCSV from '../../utils/DownloadCSV';
import useToken from '../../hooks/useToken';
import { getSalesByTable } from '../../services/sales/SalesService';
import PaginationComponent from '../shared/PaginationComponent';
import ToastError from '../../utils/ToastError';
import DateUTCFormat from '../../utils/DateUTCFormat';
import ToCurrencyMXN from '../../utils/ToCurrencyMXN';

const TableDatatableComponent = ({filters}) => {
  const [data, setData] = useState([]);
  const token = useToken();

  useEffect(()=> {

    if(filters && token){

      getSalesByTable(token, filters).then((response)=> {
        if(response.status === 200){
          setData(response.data.data);
        }
      }).catch(()=> {
        ToastError("Error al consultar información");
      });

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters]);

  const columns = [{
    name: 'Folio',
    selector: row => row.table_folio,
    sortable: true,
  },
  {
    name: 'Mesa',
    selector: row => row.table_name,
    sortable: true,
  },
  {
    name: 'Apertura',
    selector: row => row.opening_date,
    format: row => DateUTCFormat(row.opening_date, "YYYY-MM-DD HH:mm:ss"),
    sortable: true,
  },
  {
    name: 'Cierre',
    selector: row => row.closing_date,
    format: row => DateUTCFormat(row.closing_date, "YYYY-MM-DD HH:mm:ss"),
    sortable: true,
  },
  {
    name: 'Comensales',
    selector: row => row.diners,
    sortable: true,
  },
  {
    name: 'Total',
    selector: row => row.total,
    format: row => ToCurrencyMXN(row.total.toFixed(2)),
    sortable: true,
  },
  {
    name: 'Usuario',
    selector: row => row.user_name,
    sortable: true,
  }];

  createTheme('solarized', solarizedTheme, 'dark');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const actionsMemo = React.useMemo(() => <ExportCSVComponent onExport={() => DownloadCSV(data)} disabled={!data.length > 0} />, [data]);


  return (
    <div className="border rounded table-container">
      <DataTable
        columns={columns}
        data={data}
        theme="solarized"
        customStyles={customStyles}
        actions={actionsMemo}
        pagination
        paginationComponent={PaginationComponent}
        />
    </div>
  )
}

export default TableDatatableComponent;
