import React, { useEffect, useState } from "react"
import CardsComponent from "./CardsComponent"
import RandomRGBA from "../../utils/RandomRGBA";
import {Bar , Pie } from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip } from "chart.js";

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HomeComponent = ({date, data}) => {
  const [saleResume, setSaleResume] = useState({labels: [], data: []});
  const [prodSaleResume, setProdSaleResume] = useState({labels: [], data: []});
  const [waiterSaleResume, setWaiterSaleResume] = useState({labels: [], data: [], backgroundColor: [], borderColor: []});

  useEffect(() => {

    const saleResumeTemp = {labels: [], data: []};
    const prodSaleResumeTemp = {labels: [], data: []};
    const waiterSaleResumeTemp = {labels: [], data: [], backgroundColor: [], borderColor: []};

    data?.saleResumeData?.forEach((item) => {
      saleResumeTemp.labels.push(item.time);
      saleResumeTemp.data.push(item.total);
    });

    setSaleResume(saleResumeTemp);

    data?.prodSaleResume?.forEach((item)=>{
      prodSaleResumeTemp.labels.push(item.product_name);
      prodSaleResumeTemp.data.push(item.quantity);
    });

    setProdSaleResume(prodSaleResumeTemp);

    data?.waiterSaleResume?.forEach((item)=> {
      waiterSaleResumeTemp.labels.push(item.waiter_name);
      waiterSaleResumeTemp.data.push(item.sales.toFixed(2));
      waiterSaleResumeTemp.backgroundColor.push(RandomRGBA());
      waiterSaleResumeTemp.borderColor.push(RandomRGBA());
    });

    setWaiterSaleResume(waiterSaleResumeTemp);

    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data]);


  return (
    <div className="container-full">
        <div className="d-flex flex-row flex-wrap">
            <div className="col-12 col-lg-6 col-md-12 mb-5">
              <CardsComponent date={date} {...data?.dashboardData}/>
            </div>
            <div className="col-12 col-lg-6 col-md-12 graphic">
               <Bar
                data={{
                    labels: saleResume.labels,
                    datasets: [
                      {
                        label: "",
                        data: saleResume.data,
                        backgroundColor: "#74c2e050",
                      },
                    ],
                  }}
                options={{
                    responsive: true,
                    aspectRatio: 2,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Comparativo de Venta",
                      },
                    },
                  }
                }
               />
            </div>
            <div className="col-12 col-md-12 col-lg-12 graphic">
                <Bar
                    data={{
                        labels: prodSaleResume.labels,
                        datasets: [
                        {
                            label: "Probability",
                            data: prodSaleResume.data,
                            backgroundColor: "rgb(150, 121, 32, 0.75)",
                            borderColor: "rgb(150, 121, 32, 1)",
                        },
                        ],
                    }}
                    options={{
                        indexAxis: "y",
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        },
                        elements: {
                            bar: {
                                    borderWidth: 2,
                                },
                        },
                        responsive: true,
                        plugins: {
                        legend: {
                            position: "top",
                        },
                        title: {
                            display: true,
                            text: "Top 5 productos más vendidos",
                        },
                        },
                    }
                    }
                />
            </div>
            <div className="col-12 col-md-12 col-lg-4 graphic">
              <Pie
                data={{
                    labels: waiterSaleResume.labels,
                    datasets: [
                      {
                        label: 'Ventas por Mesero',
                        data: waiterSaleResume.data,
                        backgroundColor: waiterSaleResume.backgroundColor ,
                        borderColor: waiterSaleResume.borderColor,
                        borderWidth: 1,
                      },
                    ],
                  }}
                options={{
                  responsive: true,
                  aspectRatio: 2,
                  maintainAspectRatio: false,
                  plugins: {
                      title: {
                        display: true,
                        text: 'Ventas por Mesero',
                        fontSize: 16,
                      },
                    },
                  }
                }
                  />
            </div>
            <div className="col-12 col-md-12 col-lg-8 graphic">
                <Bar
                    data={{
                        labels: ["", ""],
                        datasets: [
                          {
                              label: "Gastos y Compras",
                              data: [data?.expPurSaleResume?.expenses_purchases],
                              backgroundColor: "rgb(150, 121, 0, 0.75)",
                              borderColor: "rgb(150, 121, 0, 1)",
                          },
                          {
                              label: "Ventas",
                              data: [data?.expPurSaleResume?.sales],
                              backgroundColor:  "rgb(255, 233, 124, 0.75)",
                              borderColor: "rgb(150, 121, 0, 1)",
                          }
                        ],
                    }}
                    options={{
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        },
                        elements: {
                            bar: {
                                    borderWidth: 2,
                                },
                        },
                        responsive: true,
                        plugins: {
                        legend: {
                            position: "bottom",
                        },
                        title: {
                            display: true,
                            text: "Gastos & Compras vs Ventas",
                        },
                        },
                    }
                    }
                />
            </div>
        </div>
    </div>
  )
}

export default HomeComponent
