import React from 'react'
import OpenAccount from "../../assets/img/open-account.png"
import Checks from "../../assets/img/checks.png"
import Spends from "../../assets/img/spends.png"
import Buys from "../../assets/img/buys.png"
import Discount from "../../assets/img/discount.png"
import DateUTCFormat from '../../utils/DateUTCFormat'
import ToCurrencyMXN from '../../utils/ToCurrencyMXN'

const CardsComponent = (props) => {


  return (
    <div className='d-flex flex-row flex-wrap'>
    <div className='col-12 col-md-12'>
        <div className="card card-main mb-3">
            <div className="row g-0">
                <div className="col-4 col-md-4">
                    <div className="d-flex flex-row align-content-center justify-content-center card-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#ffffff" className="bi bi-cash" viewBox="0 0 16 16">
                            <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                            <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z"/>
                        </svg>
                    </div>
                </div>
                <div className="col-8 col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">Venta Cobrada</h5>
                        <h5 className="card-title">$ {ToCurrencyMXN(props?.ventaCobrada?.toFixed(2))}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='col-12 col-md-6'>
        <div className="card card-checks mb-3">
            <div className="row g-0">
                <div className="col-4 col-md-4">
                    <div className="d-flex flex-row align-content-center justify-content-center card-img">
                        <img src={Checks} alt="checks" width={64} height={64}/>
                    </div>
                </div>
                <div className="col-8 col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">Cheque Promedio</h5>
                        <h5 className="card-title">$ {ToCurrencyMXN(props?.chequePromedio?.toFixed(2))}</h5>
                        <p className="card-text float-end pb-3">{DateUTCFormat(props.date)}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='col-12 col-md-6'>
        <div className="card card-openaccount mb-3">
            <div className="row g-0">
                <div className="col-4 col-md-4">
                    <div className="d-flex flex-row align-content-center justify-content-center card-img">
                        <img src={OpenAccount} alt="open account" width={64} height={64}/>
                    </div>
                </div>
                <div className="col-8 col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">Cuentas Abiertas</h5>
                        <h5 className="card-title">$ {ToCurrencyMXN(props?.cuentasAbiertas?.toFixed(2))}</h5>
                        <p className="card-text float-end pb-3">{DateUTCFormat(props.date)}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='col-12 col-md-6'>
        <div className="card card-spends mb-3">
            <div className="row g-0">
                <div className="col-4 col-md-4">
                    <div className="d-flex flex-row align-content-center justify-content-center card-img">
                        <img src={Spends} alt="spends" width={64} height={64}/>
                    </div>
                </div>
                <div className="col-8 col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">Gastos</h5>
                        <h5 className="card-title">$ {ToCurrencyMXN(props?.gastos?.toFixed(2))}</h5>
                        <p className="card-text float-end pb-3">{DateUTCFormat(props.date)}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='col-12 col-md-6'>
        <div className="card card-buys mb-3">
            <div className="row g-0">
                <div className="col-4 col-md-4">
                    <div className="d-flex flex-row align-content-center justify-content-center card-img">
                       <img src={Buys} alt="buys" width={64} height={64}/>
                    </div>
                </div>
                <div className="col-8 col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">Compras</h5>
                        <h5 className="card-title">$ {ToCurrencyMXN(props?.compras?.toFixed(2))}</h5>
                        <p className="card-text float-end pb-3">{DateUTCFormat(props.date)}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='col-12 col-md-6'>
        <div className="card card-cancel mb-3">
            <div className="row g-0">
                <div className="col-4 col-md-4">
                    <div className="d-flex flex-row align-content-center justify-content-center  card-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
                <div className="col-8 col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">Cancelaciones</h5>
                        <h5 className="card-title">$ {ToCurrencyMXN(props?.cancelaciones?.toFixed(2))}</h5>
                        <p className="card-text float-end pb-3">{DateUTCFormat(props.date)}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='col-12 col-md-6'>
        <div className="card card-discounts mb-3">
            <div className="row g-0">
                <div className="col-4 col-md-4">
                    <div className="d-flex flex-row align-content-center justify-content-center card-img">
                        <img src={Discount} alt="discounts" width={64} height={64}/>
                    </div>
                </div>
                <div className="col-8 col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">Descuentos</h5>
                        <h5 className="card-title">$ {ToCurrencyMXN(props?.descuentos?.toFixed(2))}</h5>
                        <p className="card-text float-end pb-3">{DateUTCFormat(props.date)}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default CardsComponent
