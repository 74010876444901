import React from 'react'
import SummaryDatatableComponent from './SummaryDatatableComponent'
import TableDatatableComponent from './TableDatatableComponent'
import ProductsDatatableComponent from './ProductsDatatableComponent'
import WaitersDatatableComponent from './WaitersDatatableComponent'

const SalesComponent = (props) => {

  return (
    <div className="container-full">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item active" role="presentation">
                    <button className="nav-link active" id="pills-summary-tab" data-bs-toggle="pill" data-bs-target="#pills-summary" type="button" role="tab" aria-controls="pills-summary" aria-selected="true">Resumen</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-tables-tab" data-bs-toggle="pill" data-bs-target="#pills-tables" type="button" role="tab" aria-controls="pills-tables" aria-selected="false">Mesas</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-products-tab" data-bs-toggle="pill" data-bs-target="#pills-products" type="button" role="tab" aria-controls="pills-products" aria-selected="false">Productos</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-waiters-tab" data-bs-toggle="pill" data-bs-target="#pills-waiters" type="button" role="tab" aria-controls="pills-waiters" aria-selected="false">Meseros</button>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade active show" id="pills-summary" role="tabpanel" aria-labelledby="pills-summary-tab" tabIndex="0">
                    <SummaryDatatableComponent {...props}/>
                </div>
                <div className="tab-pane fade" id="pills-tables" role="tabpanel" aria-labelledby="pills-tables-tab" tabIndex="0">
                    <TableDatatableComponent {...props}/>
                </div>
                <div className="tab-pane fade" id="pills-products" role="tabpanel" aria-labelledby="pills-products-tab" tabIndex="0">
                    <ProductsDatatableComponent {...props}  />
                </div>
                <div className="tab-pane fade" id="pills-waiters" role="tabpanel" aria-labelledby="pills-waiters-tab" tabIndex="0">
                    <WaitersDatatableComponent {...props} />
                </div>
            </div>
    </div>
  )
}

export default SalesComponent
