import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const ProtectedRoute = ({ page: Page }) => {
    const auth = useAuth();

    return auth ? <Page /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
