import React from "react";
import CheckposLogo from "../../../utils/CheckPOSLogo";
import ChangePasswordComponent from "../../../components/auth/change-password/ChangePasswordComponent";
import { ToastContainer } from "react-toastify";
import "./ChangePassword.css";

const ChangePassword = () => {
  return (
    <div className="vh-100 vw-100 change-password">
      <div className="container">
        <CheckposLogo width={196} height="auto"/>
        <ChangePasswordComponent />
      </div>
      <ToastContainer/>
    </div>
  );
};

export default ChangePassword;
