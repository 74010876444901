import axios from "axios";


export const getSummarySales = async (token = null, data = null) => {
    if(data && token){
        const config = {
            url: `${process.env.REACT_APP_API_URL}/SalesResume`,
            method: "POST",
            headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
            },
            data: JSON.stringify(data)
        };

        return await axios.request(config);
    }
}

export const getSalesByTable = async (token = null, data = null) => {
    if(data && token){
        const config = {
            url: `${process.env.REACT_APP_API_URL}/SalesXTables`,
            method: "POST",
            headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
            },
            data: JSON.stringify(data)
        };

        return await axios.request(config);
    }
}

export const getSalesProducts = async (token = null, data = null) => {
    if(data && token){
        const config = {
            url: `${process.env.REACT_APP_API_URL}/SalesPPM`,
            method: "POST",
            headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
            },
            data: JSON.stringify(data)
        };

        return await axios.request(config);
    }
}

export const getSalesByWaiter= async (token = null, data = null) => {
    if(data && token){
        const config = {
            url: `${process.env.REACT_APP_API_URL}/SalesXWaiter`,
            method: "POST",
            headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
            },
            data: JSON.stringify(data)
        };

        return await axios.request(config);
    }
}
