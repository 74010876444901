import React from "react";
import GetCurrentYear from "../../../utils/GetCurrentYear";
import "./Footer.css";

const Footer = () => {
  return (
  <footer className="vw-100 p-1">
    <div className="d-flex justify-content-center align-content-center form-text text-white">
      <span className="text-wrap text-center">DERECHOS RESERVADOS HG Consulting &copy; {GetCurrentYear()}</span>
    </div>
  </footer>);
};

export default Footer;
