import React, { useEffect, useState } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import DownloadCSV from '../../utils/DownloadCSV';
import ExportCSVComponent from '../shared/ExportCSVComponent';
import useToken from '../../hooks/useToken';
import PaginationComponent from '../shared/PaginationComponent';
import ToastError from '../../utils/ToastError';
import DateUTCFormat from '../../utils/DateUTCFormat';
import { customStyles } from '../shared/CustomStyles';
import { solarizedTheme } from '../shared/SolarizedTheme';
import { getSummarySales } from '../../services/sales/SalesService';

const SummaryDatatableComponent = ({filters}) => {
  const token = useToken();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(()=> {

    if(filters && token){
      getSummarySales(token, filters).then((response)=> {
        if(response.status === 200){

          const data = response.data.data;

          if(data.length > 0){

            setColumns([
              {
                name: 'Fecha',
                selector: row => row.close_date,
                format: row => DateUTCFormat(row.date,"YYYY-MM-DD HH:mm:ss"),
                sortable: true,
              },
              ...data[0]?.payment_methods?.map((item)=> {

                  const name = item.name.replaceAll(' ','_').toLowerCase();

                  return {
                    name: item.name,
                    selector: row => row[name],
                    format: row => typeof row[name] === "number" ? row[name].toFixed(2):row[name],
                    sortable: true
                  };
                })
              ]);

            setData(data.map((item)=> {

              let payment_methods = {};

              item.payment_methods.forEach((item)=> {
                const name = item.name.replaceAll(' ','_').toLowerCase();
                payment_methods[name] = item.total
              })

              return {id: item.id, close_date: item.close_date, ...payment_methods}
            }));

          }
        }
      }).catch(()=> {
        ToastError("Error al consultar la información");
      });
    }

    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters]);

  createTheme('solarized', solarizedTheme, 'dark');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const actionsMemo = React.useMemo(() => <ExportCSVComponent onExport={() => DownloadCSV(data)} disabled={!data.length > 0} />, [data]);

  return (
    <div className="border rounded table-container">
        <DataTable
          theme="solarized"
          customStyles={customStyles}
          actions={actionsMemo}
          columns={columns}
          data={data}
          pagination
          paginationComponent={PaginationComponent}
          />
    </div>
  )
}

export default SummaryDatatableComponent;
