import React from "react";
import { useFormik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import IsInvalidError from "../../utils/IsInvalidError";
import * as yup from "yup";
import GetCurrentYear from "../../utils/GetCurrentYear";
import { postSignin } from "../../services/login/LoginService";
import ToastError from "../../utils/ToastError";
import ToastInfo from "../../utils/ToastInfo";

const LoginComponent = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: yup.object({
      email: yup
        .string("Ingrese su correo electronico.")
        .email("Ingrese un correo electrónico valido.")
        .required("Su correo electrónico es requerido."),
      password: yup
        .string("Ingrese su contraseña")
        .required("Su contraseña es requerida."),
    }),
    onSubmit: (values) => {

      const credentials = {"usuario": values.email, "contraseña": values.password};

      ToastInfo("Cargando...");

      postSignin(credentials)
      .then((response) => {
        if(response.status === 200){
          const data = response.data.data;
          const token = data.token;
          const user = JSON.stringify(data.usuario);
          const branch = JSON.stringify(data.sucursales);

          sessionStorage.setItem("token", token);
          sessionStorage.setItem("user",user);
          sessionStorage.setItem("branchs",branch);

          navigate("/");
        }
      }).catch((error)=> {

        ToastError("Error: Correo o Contraseña no validos.");

      });

    },
  });

  return (
    <form className="mt-4" onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        <label htmlFor="email" className="form-label text-white">
          Correo electrónico
        </label>
        <input
          type="email"
          className={IsInvalidError(formik, "email")}
          id="email"
          name="email"
          placeholder="Ingrese su correo electrónico."
          value={formik.values.email}
          onChange={formik.handleChange}
          aria-describedby="emailHelp"
          autoComplete="off"
        />
        <div className="invalid-feedback">{formik.errors.email}</div>
        <div id="emailHelp" className="form-text text-info">
          Nunca compartas tu correo con nadie.
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label text-white">
          Contraseña
        </label>
        <input
          type="password"
          className={IsInvalidError(formik, "password")}
          id="password"
          name="password"
          placeholder="Ingrese su contraseña."
          value={formik.values.password}
          onChange={formik.handleChange}
          aria-describedby="passwordHelp"
          autoComplete="off"
        />
        <div className="invalid-feedback">{formik.errors.password}</div>
        <div id="passwordHelp" className="form-text text-info">
          <NavLink className="nav-link text-info" to="/recover-password">
            ¿Olvidaste tu Contraseña?
          </NavLink>
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-primary col-12 col-md-12 col-sm-12 mb-3"
      >
        Ingresar
      </button>

      <div className="d-flex justify-content-center align-content-center form-text text-white">
        <span className="text-wrap text-center">DERECHOS RESERVADOS HG Consulting &copy; {GetCurrentYear()}</span>
      </div>
    </form>
  );
};

export default LoginComponent;
