import axios from "axios";

export const postSignin = async (credentials = null) => {

    if(credentials){
        const config = {
            url: `${process.env.REACT_APP_API_URL}/Login`,
            method: "POST",
            headers: {
            "Content-Type": "application/json"
            },
            data: JSON.stringify(credentials)
        };

        return await axios.request(config);
    }

}


