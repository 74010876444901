import React, { useEffect } from "react"
import DateUTCFormat from "../../utils/DateUTCFormat";
import ToCurrencyMXN from "../../utils/ToCurrencyMXN";
import ToCapitalizeAll from "../../utils/ToCapitalizeAll";

const DetailsComponent = ({details}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps


  useEffect(()=> {

    const collapseElements = document.querySelectorAll('[data-bs-toggle="collapse"]');

    collapseElements.forEach((collapse) => {

      collapse.addEventListener('click', () => {

        const icon = collapse.lastChild;

        if(!collapse.classList.contains('disabled')){

            if (collapse.classList.contains("collapsed")) {
                icon?.classList.toggle('bi-plus');
                icon?.classList.toggle('bi-dash');
            } else {
                icon?.classList.toggle('bi-dash');
                icon?.classList.toggle('bi-plus');
            }
        }
      });

      const collapseId = collapse?.href?.split('#')[1];
      const elements = document.getElementById(collapseId);
      const isCollapseEmpty = !elements || !elements.innerHTML.trim();
      collapse?.classList?.toggle('disabled', isCollapseEmpty);

    });


    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[details]);

  const totalPaymentMethod = details?.sales_detail?.sales_payment_methods.reduce((accumulator, item) => accumulator + item.total,0);
  const totalTypeService = details?.sales_detail?.sales_type_of_service.reduce((accumulator, item) => accumulator + item.total,0);
  const totalSalesByFamily = details?.sales_detail?.sales_by_family.reduce((accumulator, item) => accumulator + item.total,0);
  const totalSalesByCategory = details?.sales_detail?.sales_by_category.reduce((accumulator, item) => accumulator + item.total,0);



  return (
    <div className="rounded rounded-top-0 border border-top-0">
        {/* box cut */}
        <div className="col-12 col-md-12 col-lg-12">
            <h1 className="title p-5 mb-5">Corte de Caja</h1>
        </div>
        {/* box cut information  */}
        <div className="d-flex m-2">
            <div className="col-12 col-md-12 col-lg-12">
                <div className="d-flex flex-column flex-md-row mb-4">
                    <div className="col-12 col-md-4 col-lg-4">
                        <div className="d-flex flex-column align-items-center">
                            <h3 className="title">Apertura de Caja</h3>
                            <span className="fs-4 fw-semibold">{DateUTCFormat(details?.information?.opening_date,"YYYY/MM/DD HH:mm:ss a")}</span>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <div className="d-flex flex-column align-items-center">
                            <h1 className="title text-dark fs-2">${ToCurrencyMXN(details?.information?.opening_cash.toFixed(2))}</h1>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <div className="d-flex flex-column align-items-center">
                            <h3 className="title">{details?.information?.missing_surplus < 0 ? "Faltante": "Sobrante"}</h3>
                            <h1 className="title text-dark fs-4">${ToCurrencyMXN(details?.information?.missing_surplus)}</h1>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row">
                    <div className="col-12 col-md-4 col-lg-4">
                        <div className="d-flex flex-column align-items-center">
                            <h3 className="title">Cierre de Caja</h3>
                            <span className="fs-4 fw-semibold">{DateUTCFormat(details?.information?.closing_date,"YYYY/MM/DD HH:mm:ss a")}</span>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <div className="d-flex flex-column align-items-center">
                            <h1 className="title text-dark fs-2">${ToCurrencyMXN(details?.information?.closing_cash.toFixed(2))}</h1>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <div className="d-flex flex-column align-items-center">
                            <h3 className="title">Deberia haber</h3>
                            <h1 className="title text-dark fs-4">{ToCurrencyMXN(details?.information?.should_be.toFixed(2))}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* comments */}
        <div className="d-flex m-2">
            <div className="col-12 col-md-12 col-lg-12">
                <div className="d-flex flex-row mb-4">
                    <div className="col-12 col-md-4 col-lg-4">
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <div className="d-flex flex-column align-items-center">
                            <h3 className="title">Comentarios</h3>
                            <span className="fs-5 fw-semibold text-wrap">{details?.comments}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* total sales */}
        <div className="d-flex flex-row gap-2 space-around p-2">
            <div className="col">
                <a data-bs-toggle="collapse" href="#total_sales" className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls="total_sales"><i className="bi bi-plus text-nowrap">Total Venta</i></a>
            </div>
            <div className="col">
                <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(totalPaymentMethod)}</span>
            </div>
        </div>

        <div className="d-flex flex-column">
            <div className="col" style={{marginLeft: "10px"}}>
                <div className="collapse multi-collapse" id="total_sales">
                    <div className="d-flex flex-row gap-2 space-around p-2">
                        <div className="col">
                            <a data-bs-toggle="collapse" href="#total_sales_payment_method" className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls="total_sales_payment_method" ><i className="bi bi-plus text-nowrap">Venta por forma de pago</i></a>
                        </div>
                        <div className="col">
                            <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(totalPaymentMethod)}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="collapse multi-collapse" id="total_sales_payment_method">
                               {details?.sales_detail?.sales_payment_methods?.map((item, index)=>
                                <div className="d-flex flex-row gap-2 space-bettewn" key={index} style={{marginLeft: "15px", marginRight: "8px"}}>
                                    <div className="col">
                                        <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{item.payment_method_name}</span>
                                    </div>
                                    <div className="col">
                                        <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-secondary">${ToCurrencyMXN(item.total)}</span>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row gap-2 space-around p-2">
                        <div className="col">
                            <a data-bs-toggle="collapse" href="#total_sales_type_service" className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls="total_sales_type_service"><i className="bi bi-plus text-nowrap">Venta por tipo de servicio</i></a>
                        </div>
                        <div className="col">
                            <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(totalTypeService)}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="collapse multi-collapse" id="total_sales_type_service">
                               {details?.sales_detail?.sales_type_of_service?.map((item , index)=>
                                <div className="d-flex flex-row gap-2 space-bettewn" key={index} style={{marginLeft: "15px", marginRight: "8px"}}>
                                    <div className="col">
                                        <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{ToCapitalizeAll(item.type_of_service)}</span>
                                    </div>
                                    <div className="col">
                                        <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{item.quantity}</span>
                                    </div>
                                    <div className="col">
                                        <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-secondary">${ToCurrencyMXN(item.total)}</span>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row gap-2 space-around p-2">
                        <div className="col">
                            <a data-bs-toggle="collapse" href="#sales_by_family" className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls="sales_by_family"><i className="bi bi-plus text-nowrap">Ventas por familias</i></a>
                        </div>
                        <div className="col">
                            <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(totalSalesByFamily)}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="collapse multi-collapse" id="sales_by_family">
                               {details?.sales_detail?.sales_by_family?.map((item, index)=>
                                <div className="d-flex flex-row gap-2 space-bettewn" key={index} style={{marginLeft: "15px", marginRight: "8px"}}>
                                    <div className="col">
                                        <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{ToCapitalizeAll(item.family_name)}</span>
                                    </div>
                                    <div className="col">
                                        <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{item.quantity}</span>
                                    </div>
                                    <div className="col">
                                        <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-secondary">${ToCurrencyMXN(item.total)}</span>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row gap-2 space-around p-2">
                        <div className="col">
                            <a data-bs-toggle="collapse" href="#sales_by_category" className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls="sales_by_category"><i className="bi bi-plus text-nowrap">Ventas por categoria</i></a>
                        </div>
                        <div className="col">
                            <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(totalSalesByCategory)}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="collapse multi-collapse" id="sales_by_category">
                               {details?.sales_detail?.sales_by_category?.map((item, index)=>
                                <div className="d-flex flex-row gap-2 space-bettewn" key={index} style={{marginLeft: "15px", marginRight: "8px"}}>
                                    <div className="col">
                                        <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{ToCapitalizeAll(item.category_name)}</span>
                                    </div>
                                    <div className="col">
                                        <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{item.quantity}</span>
                                    </div>
                                    <div className="col">
                                        <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-secondary">${ToCurrencyMXN(item.total)}</span>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="d-flex flex-row gap-2 space-around p-2">
            <div className="col">
                <a data-bs-toggle="collapse" href="#expenses" className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls="expenses"><i className="bi bi-plus text-nowrap">Egresos</i></a>
            </div>
            <div className="col">
                <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(details?.expenses_detail?.reduce((accumulator, item)=> accumulator + item.expenses.reduce((accumulator, item)=> accumulator + item.total, 0),0))}</span>
            </div>
        </div>

        <div className="d-flex flex-column gap-2 space-around ps-2">
            <div className="col" style={{marginLeft: "15px", marginRight: "8px"}}>
                <div className="collapse multi-collapse" id="expenses">
                    {details?.expenses_detail.map((item, index)=> {

                    const labelId = item.expense_category.toLowerCase().replaceAll(' ','_').replace(/[^\w\s]|_/g, "") + "_id";

                    return <div key={index}>
                        <div className="d-flex flex-row gap-2 space-around p-2">
                            <div className="col">
                                <a data-bs-toggle="collapse" href={`#${labelId}`} className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls={`#${labelId}`}><i className="bi bi-plus text-nowrap">{ToCapitalizeAll(item.expense_category)}</i></a>
                            </div>
                            <div className="col">
                                <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(item.expenses.reduce((accumulator, item)=> accumulator + item.total, 0))}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" style={{marginLeft: "20px"}}>
                                <div className="collapse multi-collapse" id={`${labelId}`}>
                                {item.expenses?.map((item, index)=>
                                    <div className="d-flex flex-row gap-2 space-around p-2" key={index} style={{marginLeft: "15px", marginRight: "5px"}}>
                                        <div className="col">
                                            <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{ToCapitalizeAll(item.reason_expense)}</span>
                                        </div>
                                        <div className="col">
                                            <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-secondary">${ToCurrencyMXN(item.total)}</span>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>;
                    })}
                </div>
            </div>

        </div>

        <div className="d-flex flex-row gap-2 space-around p-2">
                <div className="col">
                    <a data-bs-toggle="collapse" href="#incomes_detail" className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls="incomes_detail"><i className="bi bi-plus text-nowrap">Ingresos</i></a>
                </div>
                <div className="col">
                    <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(details?.incomes_detail?.reduce((accumulator, item)=> accumulator + item.incomes?.reduce((accumulator, item) => accumulator + item.total, 0),0))}</span>
                </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="collapse multi-collapse" id="incomes_detail">
                {details?.incomes_detail?.map((item, index)=> {

                    const labelId = item?.income_category?.toLowerCase().replaceAll(' ','_').replace(/[^\w\s]|_/g, "") + "_id";

                    return <>
                        <div className="d-flex flex-row gap-2 space-around p-2" key={index} style={{marginLeft: "10px"}}>
                            <div className="col">
                                <a data-bs-toggle="collapse" href={`#${labelId}`} className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls={`#${labelId}`}><i className="bi bi-plus text-nowrap">{ToCapitalizeAll(item.income_category)}</i></a>
                            </div>
                            <div className="col">
                                <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(item.incomes?.reduce((accumulator, item)=> accumulator + item.total,0))}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" style={{marginLeft: "15px"}}>
                                <div className="collapse multi-collapse" id={`${labelId}`}>
                                    {item?.incomes?.map((item,index)=>
                                        <div className="d-flex flex-row gap-2 space-around p-2" key={index} style={{marginLeft: "15px"}}>
                                            <div className="col">
                                                <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{ToCapitalizeAll(item.reason_income)}</span>
                                            </div>
                                            <div className="col">
                                                <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-secondary">${ToCurrencyMXN(item.total)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>;
                    })}

                </div>
            </div>
        </div>

        <div className="d-flex flex-row gap-2 space-around p-2">
                <div className="col">
                    <a data-bs-toggle="collapse" href="#discounts" className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls="discounts"><i className="bi bi-plus text-nowrap">Descuentos</i></a>
                </div>
                <div className="col">
                    <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(details?.discounts_detail.reduce((accumulator, item)=> accumulator + item.discounts.reduce((accumulator, item) => accumulator + item.total, 0),0))}</span>
                </div>
        </div>

        <div className="row">
            <div className="col">
                <div className="collapse multi-collapse" id="discounts">
                {details?.discounts_detail.map((item, index)=> {

                    const labelId = item.discount_reason.toLowerCase().replaceAll(' ','_').replace(/[^\w\s]|_/g, "") + "_id";

                    return <>
                        <div className="d-flex flex-row gap-2 space-around p-2" key={index} style={{marginLeft: "10px"}}>
                            <div className="col">
                                <a data-bs-toggle="collapse" href={`#${labelId}`} className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls={`#${labelId}`}><i className="bi bi-plus text-nowrap">{ToCapitalizeAll(item.discount_reason)}</i></a>
                            </div>
                            <div className="col">
                                <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(item.discounts.reduce((accumulator, item)=> accumulator + item.total,0))}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" style={{marginLeft: "15px"}}>
                                <div className="collapse multi-collapse" id={`${labelId}`}>
                                    {item?.discounts?.map((item,index)=>
                                        <div className="d-flex flex-row gap-2 space-around p-2" key={index} style={{marginLeft: "15px"}}>
                                            <div className="col">
                                                <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{ToCapitalizeAll(item.table_name)}</span>
                                            </div>
                                            <div className="col">
                                                <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{ToCapitalizeAll(item.user_name)}</span>
                                            </div>
                                            <div className="col">
                                                <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-secondary">${ToCurrencyMXN(item.total)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>;
                    })}

                </div>
            </div>
        </div>

        <div className="d-flex flex-row gap-2 space-around p-2">
            <div className="col">
                <a data-bs-toggle="collapse" href="#cancellations" className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls="cancellations"><i className="bi bi-plus text-nowrap">Cancelaciones</i></a>
            </div>
            <div className="col">
                <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(details?.cancellations_detail.reduce((accumulator, item)=> accumulator + item.cancellations.reduce((accumulator, item) => accumulator + item.total, 0),0))}</span>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="collapse multi-collapse" id="cancellations">
                {details?.cancellations_detail.map((item, index)=> {

                    const labelId = item.cancellation_reason.toLowerCase().replaceAll(' ','_').replace(/[^\w\s]|_/g, "") + "_id";

                    return <>
                        <div className="d-flex flex-row gap-2 space-around p-2" key={index} style={{marginLeft: "10px"}}>
                            <div className="col">
                                <a data-bs-toggle="collapse" href={`#${labelId}`} className="text-decoration-none fw-semibold fs-5 text-primary" role="button" aria-expanded="false" aria-controls={`#${labelId}`}><i className="bi bi-plus text-nowrap">{ToCapitalizeAll(item.cancellation_reason)}</i></a>
                            </div>
                            <div className="col">
                                <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-primary">${ToCurrencyMXN(item.cancellations.reduce((accumulator, item)=> accumulator + item.total,0))}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" style={{marginLeft: "15px"}}>
                                <div className="collapse multi-collapse" id={`${labelId}`}>
                                    {item?.cancellations?.map((item, index)=>
                                        <div className="d-flex flex-row gap-2 space-around p-2" key={index} style={{marginLeft: "15px"}}>
                                            <div className="col">
                                                <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{ToCapitalizeAll(item.product_name)}</span>
                                            </div>
                                            <div className="col">
                                                <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{ToCapitalizeAll(item.table_name)}</span>
                                            </div>
                                            <div className="col">
                                                <span className="d-flex flex-start justify-content-start fw-semibold fs-5 text-secondary">{ToCapitalizeAll(item.user_name)}</span>
                                            </div>
                                            <div className="col">
                                                <span className="d-flex flex-end justify-content-end fw-semibold fs-5 text-secondary">${ToCurrencyMXN(item.total)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>;
                    })}

                </div>
            </div>
        </div>
    </div>
  )
}

export default DetailsComponent;
