import React, { useEffect, useState } from 'react'
import useToken from '../../hooks/useToken';
import DataTable, {createTheme} from "react-data-table-component";
import PaginationComponent from '../shared/PaginationComponent';
import ExportCSVComponent from '../shared/ExportCSVComponent';
import DownloadCSV from '../../utils/DownloadCSV';
import DateUTCFormat from '../../utils/DateUTCFormat';
import ToastError from '../../utils/ToastError';
import { solarizedTheme } from '../shared/SolarizedTheme';
import { customStyles } from '../shared/CustomStyles';
import { getBoxCut } from '../../services/box_cut/BoxCutService';

const BoxCutDatatableComponent = ({filters, handleSelectDetails}) => {
    const [data, setData] = useState([]);
    const token = useToken();

    useEffect(()=> {

        if(token && filters && filters?.branch_id !== 0 && filters?.branch_id !== undefined){

            getBoxCut(token, {...filters, date: DateUTCFormat(filters?.date, 'YYYY-MM-DD')}).then((response) => {
                if(response.status === 200){
                    setData(response.data.data);
                }
            }).catch(()=> {
                ToastError("Error al consultar la información.")
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filters])

    createTheme('solarized',solarizedTheme, 'dark');

    const columns = [
      {
        name: 'Turno',
        selector: row => row.turn,
        sortable: true,
      },
      {
        name: 'Fecha de Apertura',
        selector: row => row.information.opening_date,
        format: row => DateUTCFormat(row.information.opening_date, 'YYYY-MM-DD HH:mm:ss'),
        sortable: true,
      },
      { name: 'Apertura Caja',
        selector: row => row.information.opening_cash,
        format: row => row.information.opening_cash.toFixed(2),
        sortable: true,
      },
      {
        name: 'Fecha de Cierre',
        selector: row => row.information.closing_date,
        format: row => DateUTCFormat(row.information.closing_date, 'YYYY-MM-DD HH:mm:ss'),
        sortable: true,
      },
      { name: 'Cierre Caja',
        selector: row => row.information.closing_cash,
        format: row => row.information.closing_cash.toFixed(2),
        sortable: true,
      },
      {
        name: 'Comentarios',
        selector: row => row.information.comments,
        sortable: true,
      },
      {
        name: 'Deberia haber',
        selector: row => row.information.should_be.toFixed(2),
        sortable: true,
      },
      {
        name: 'Actions',
        cell: row =>  {

          return  <button className='btn' onClick={() => handleSelectDetails(row)}>
                    <i className="bi bi-eye"></i>
                  </button>;
        }

      }
    ];

    const actionsMemo = React.useMemo(() => <ExportCSVComponent onExport={() => DownloadCSV(data)} disabled={!data.length > 0} />, [data]);

    return (
        <div className='container-full'>
            <div className="border border-top-0 rounded-bottom table-container">
                <DataTable
                columns={columns}
                data={data}
                theme="solarized"
                customStyles={customStyles}
                actions={actionsMemo}
                pagination
                paginationComponent={PaginationComponent}
                />
          </div>
      </div>
    )
}

export default BoxCutDatatableComponent;
