import React from 'react'

const SelectBranchComponent = (props) => {

  return (
    <select
        className={props.className}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        autoComplete={props.autoComplete}
    >
        <option value="0" disabled>seleccione una sucursal</option>
        {props.data && props.data?.map((branch)=>  <option value={branch.id} key={branch.id}>{branch.name}</option>)}
    </select>
  )
}

export default SelectBranchComponent;
