import { toast } from 'react-toastify';

const ToastError = (message) => {

  toast(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 3000,
    style: {
      background: "#ff0000",
      color: "#ffffff"
    },
    progressStyle: {
      background: 'linear-gradient(to right, #ffffff, #ff0000)'
    }
  });
}

export default ToastError
