import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import IsInvalidError from "../../../utils/IsInvalidError";
import * as yup from "yup";
import GetCurrentYear from "../../../utils/GetCurrentYear";
import { postChangePassword } from "../../../services/auth/AuthService";
import ToastInfo from "../../../utils/ToastInfo";
import ToastError from "../../../utils/ToastError";

const ChangePasswordComponent = () => {
  const navigate = useNavigate();
  const [isSend, setIsSend] = useState(false);
  const {token} = useParams();


  useEffect(()=> {
    if(!token) {
      navigate("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const formik = useFormik({
    initialValues: { password: "", password_confirmation: "" },
    validationSchema: yup.object({
      password: yup
      .string("Ingrese su contraseña")
      .min(6, 'La contraseña debe ser de almenos 6 caracteres.')
      .required("La contraseña es requerida."),
      password_confirmation: yup
      .string("Ingrese su contraseña")
      .oneOf([yup.ref("password"), null], "La contraseña no coinciden.")
      .required("La confirmación de contraseña es requerida."),
    }),
    onSubmit: async (values) => {
      setIsSend(true);

      const data = {newPassword: values.password_confirmation};

      await postChangePassword(token, data).then((response)=> {
        if(response.status === 200){
          ToastInfo("Contraseña actualizada correctamente.");
          setTimeout(()=> {
            setIsSend(false);
            navigate("/login");
          },1500);
        }
      }).catch((error)=> {
        const response = error.response;
        if(response?.status === 400){
          response?.data?.errorMessages.map((item)=> ToastError(item))
          setIsSend(false);
        }
      });
    },
  });

  const handleBack = () => navigate(-1);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        <label htmlFor="password" className="form-label text-white">
          Contraseña
        </label>
        <input
          type="password"
          className={IsInvalidError(formik, "password")}
          id="password"
          name="password"
          placeholder="Ingrese su contraseña."
          value={formik.values.password}
          onChange={formik.handleChange}
          autoComplete="off"
        />
        <div className="invalid-feedback">{formik.errors.password}</div>
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label text-white">
          Confirmar Contraseña
        </label>
        <input
          type="password"
          className={IsInvalidError(formik, "password_confirmation")}
          id="password_confirmation"
          name="password_confirmation"
          placeholder="Ingrese su contraseña."
          value={formik.values.password_confirmation}
          onChange={formik.handleChange}
          autoComplete="off"
        />
        <div className="invalid-feedback">{formik.errors.password_confirmation}</div>
      </div>
      <button
        type="submit"
        className="btn btn-primary col-12 col-md-12 col-sm-12 mb-3"
        disabled={isSend}
      >
        {isSend ? "Cambiando..." : "Cambiar Contraseña"}
      </button>
      <button
        type="button"
        className="btn btn-danger text-danger col-12 col-md-12 col-sm-12 mb-3 fs-6"
        onClick={handleBack}
        disabled={isSend}
      >
        Regresar
      </button>

      <div className="d-flex justify-content-center align-content-center form-text text-white">
        <span className="text-wrap text-center">DERECHOS RESERVADOS HG Consulting &copy; {GetCurrentYear()}</span>
      </div>
    </form>
  );
};

export default ChangePasswordComponent;
