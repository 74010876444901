import axios from "axios";

export const getDiscounts = async (token = null, data = null) => {
    if(data && token){
        const config = {
            url: `${process.env.REACT_APP_API_URL}/Discounts`,
            method: "POST",
            headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
            },
            data: JSON.stringify(data)
        };

        return await axios.request(config);
    }
}

export const getDiscountsDetails = async (token = null, data = null) => {
    if(data && token){
        const config = {
            url: `${process.env.REACT_APP_API_URL}/DiscountsDetail`,
            method: "POST",
            headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
            },
            data: JSON.stringify(data)
        };

        return await axios.request(config);
    }
}
