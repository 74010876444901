import React from "react";
import Logo from "../assets/img/logo.png";

const CheckposLogo = ({ width, height, className="pb-4" }) => {
  return (
    <div className={className}>
      <img src={Logo} alt="logo" width={width} height={height} />
    </div>
  );
};

export default CheckposLogo;
