import React from "react";
import RecoverPasswordComponent from "../../../components/auth/recover-password/RecoverPasswordComponent";
import "./RecoverPassword.css";
import CheckposLogo from "../../../utils/CheckPOSLogo";
import { ToastContainer } from "react-toastify";


const RecoverPassword = () => {
  return (
    <div className="vh-100 vw-100 recovered-password">
      <div className="container">
        <CheckposLogo width={196} height="auto"/>
        <RecoverPasswordComponent />
      </div>
      <ToastContainer/>
    </div>
  );
};

export default RecoverPassword;
