const ConvertArrayOfObjectsToCSV = (array) => {

	if(array?.length > 0){

    	let result;

    	const columnDelimiter = ',';
    	const lineDelimiter = '\n';
    	const keys = Object.keys(array[0]);

    	result = '';
    	result += keys.join(columnDelimiter);
    	result += lineDelimiter;

    	array.forEach(item => {
    		let ctr = 0;
    		keys.forEach(key => {
    			if (ctr > 0) result += columnDelimiter;

    			result += item[key];

    			ctr++;
    		});
    		result += lineDelimiter;
    	});

    	return result;
    }
}

export default ConvertArrayOfObjectsToCSV;
