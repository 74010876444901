import React, { useState } from 'react'
import BoxCutComponent from '../../components/box_cut/BoxCutComponent';
import ToolbarCutZComponent from '../../components/shared/ToolbarCutZComponent';
import './BoxCut.css';

const BoxCut = () => {
  const [filters, setFilters] = useState({});

  const handleSyncData = (values) => {
    setFilters(values);
  };


  return (
    <main className="boxcut">
        <ToolbarCutZComponent
          title="Corte de Caja"
          handleSyncData={handleSyncData}
        />
        <div className="datetime-range">
          {/* Agregar la fecha de corte de caja a consultar */}
        </div>
        <div className="m-4">
          <BoxCutComponent filters={filters} />
        </div>
    </main>
  )
}

export default BoxCut;
