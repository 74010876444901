import React from "react";
import CheckposLogo from "../../../utils/CheckPOSLogo";
import { NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import useUser from "../../../hooks/useUser";

const Navbar = () => {
  const navigate = useNavigate();
  const user = useUser();

  const logout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <CheckposLogo
            width={128}
            height="auto"
            className="d-flex justify-content-center"
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                Inicio
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/sales">
                Ventas
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/cancellations">
                Cancelaciones
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/discounts">
                Descuentos
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/boxcut">
                Corte de Caja
              </NavLink>
            </li>
          </ul>
          <div className="nav-item nav-mobile">
            <span className="nav-link pr-2">
                {user.name}
            </span>
          </div>
          <div className="nav-item nav-mobile">
            <button className="nav-link" onClick={logout}>
              Cerrar Sesión
            </button>
          </div>
        </div>
        <div className="d-flex nav-desktop">
          <span className="nav-link p-2">
              {user.name}
          </span>
        </div>
        <div className="d-flex nav-desktop">
          <button className="nav-link text-nowrap" onClick={logout}>
            Cerrar Sesión
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
