import React, { useEffect, useState } from "react"
import "./Home.css";
import ToolbarComponent from "../../components/shared/ToolbarComponent";
import HomeComponent from "../../components/home/HomeComponent";
import { postHomeInfoBranch } from "../../services/home/HomeService";
import ToastError from "../../utils/ToastError";
import useToken from "../../hooks/useToken";
import DateRangeTitleComponent from "../../components/shared/DateRangeTitleComponent";
import { CalculateProfit } from "../../utils/CalculateProfit";

const Home = () => {
  const token = useToken();
  const [data, setData] = useState();

  useEffect(()=> {

    return () => {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleSyncData = async (values) => {

    if(token) {

      await postHomeInfoBranch(token, values)
      .then((response) => {
        if(response.status === 200){
          setData(response.data.data)
        }
      })
      .catch(()=>{
        ToastError("Error al consultar información");
      });

    }

  };

  const expenses = parseFloat(data?.dashboardData?.gastos) + parseFloat(data?.dashboardData?.compras);
  const sales = parseFloat(data?.dashboardData?.ventaCobrada);
  const calculateProfit = CalculateProfit(expenses, sales);


  return (
    <main className="home">
      <ToolbarComponent
        title="Analítico del Negocio"
        handleSyncData={handleSyncData}
      />
      <div className="datetime-range">
        <DateRangeTitleComponent range={data?.dashboardData?.fechaReporte}/>
      </div>
      <div className="col-12 col-lg-12 col-md-12">
        <h2 className="text-center text-secondary fst-italic" >Utilidad: ${calculateProfit} </h2>
      </div>
      <div className="m-4">
        <HomeComponent date={new Date().toUTCString()} data={data}/>
      </div>
    </main>
  );
}

export default Home
