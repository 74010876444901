import React, { useEffect, useState } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { solarizedTheme } from '../shared/SolarizedTheme';
import { customStyles } from '../shared/CustomStyles';
import ExportCSVComponent from '../shared/ExportCSVComponent';
import DownloadCSV from '../../utils/DownloadCSV';
import PaginationComponent from '../shared/PaginationComponent';
import useToken from '../../hooks/useToken';
import { getSalesByWaiter } from '../../services/sales/SalesService';
import ToastError from '../../utils/ToastError';
import ToCurrencyMXN from '../../utils/ToCurrencyMXN';

const WaitersDatatableComponent = ({filters}) => {
  const [data, setData] = useState([]);
  const token = useToken();

  useEffect(()=> {

    if(token && filters){
      getSalesByWaiter(token,filters).then((response)=> {
        if(response.status === 200) {
          setData(response.data.data);
        }
      }).catch(()=> {
        ToastError("Error al consultar información");
      })
    }

    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters])

  createTheme('solarized', solarizedTheme, 'dark');

  const columns = [
    {
      name: 'Mesero',
      selector: row => row.waiter_name,
      sortable: true,
    },
    {
      name: 'Venta',
      selector: row => row.total_sales,
      format: row => ToCurrencyMXN(row.total_sales.toFixed(2)),
      sortable: true,
    },
    {
      name: 'Comensales',
      selector: row => row.diners,
      sortable: true,
    },
    {
      name: 'Cheque Promedio',
      selector: row => row.average_check,
      format: row => ToCurrencyMXN(row.average_check.toFixed(2)),
      sortable: true,
    },
    {
      name: 'Total Propina',
      selector: row => row.total_tip,
      format: row => ToCurrencyMXN(row.total_tip.toFixed(2)),
      sortable: true,
    },
    {
      name: 'Dias Trabajados',
      selector: row => row.worked_days,
      sortable: true,
    }
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const actionsMemo = React.useMemo(() => <ExportCSVComponent onExport={() => DownloadCSV(data)} disabled={!data.length > 0} />, [data]);

  return (
    <div className="border rounded table-container">
      <DataTable
        columns={columns}
        data={data}
        theme="solarized"
        customStyles={customStyles}
        actions={actionsMemo}
        pagination
        paginationComponent={PaginationComponent}
        />
    </div>
  )
}

export default WaitersDatatableComponent
