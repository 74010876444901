import React from 'react'
import "dayjs/locale/es";

const DateRangeTitleComponent = (props) => {

    const title = props?.range?.replaceAll("-","/");

    return <h4 className='title'>Reporte: {title}</h4>

}

export default DateRangeTitleComponent
