export const solarizedTheme = {
    text: {
      primary: '#71639e',
      secondary: '#2aa198',
    },
    background: {
      default: '#ffffff',
    },
    context: {
      background: '#ffffff',
      text: '#FFFFFF',
    },
    divider: {
      default: '#413db0',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  };
