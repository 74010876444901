import React, { useEffect, useState } from 'react'
import useToken from '../../hooks/useToken';
import DataTable, {createTheme} from "react-data-table-component";
import PaginationComponent from '../shared/PaginationComponent';
import ExportCSVComponent from '../shared/ExportCSVComponent';
import DownloadCSV from '../../utils/DownloadCSV';
import DateUTCFormat from '../../utils/DateUTCFormat';
import ToCurrencyMXN from '../../utils/ToCurrencyMXN';
import ToastError from '../../utils/ToastError';
import { solarizedTheme } from '../shared/SolarizedTheme';
import { customStyles } from '../shared/CustomStyles';
import { getCancellations } from '../../services/cancellations/CancellationService';

const SummaryDatatableComponent = ({filters, handleSelectDetails}) => {
    const [data, setData] = useState([]);
    const token = useToken();

    useEffect(()=> {

        if(token && filters){

            getCancellations(token, filters).then((response) => {
                if(response.status === 200){
                    setData(response.data.data);
                }
            }).catch(()=> {
                ToastError("Error al consultar la información.")
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filters])

    createTheme('solarized',solarizedTheme, 'dark');

    const columns = [
      {
        name: 'Fecha',
        selector: row => row.cancellation_date,
        format: row => DateUTCFormat(row.cancellation_date, 'DD/MMMM/YYYY'),
        sortable: true,
      },
      {
        name: 'Costo',
        selector: row => row.total_amount,
        format: row => ToCurrencyMXN(row.total_amount.toFixed(2)),
        sortable: true,
      },
      {
        name: 'Cantidad',
        selector: row => row.quantity,
        sortable: true,
      },
      {
        name: 'Actions',
        cell: row => {
            return <button className='btn' onClick={() => handleSelectDetails(row.cancellation_date)}>
                        <i className="bi bi-eye"></i>
                   </button>
        }
      }
    ];


    const actionsMemo = React.useMemo(() => <ExportCSVComponent onExport={() => DownloadCSV(data)} disabled={!data.length > 0} />, [data]);

    return (
        <div className='container-full'>
            <div className="border border-top-0 rounded-bottom table-container">
                <DataTable
                columns={columns}
                data={data}
                theme="solarized"
                customStyles={customStyles}
                actions={actionsMemo}
                pagination
                paginationComponent={PaginationComponent}
                />
          </div>
      </div>
    )
}

export default SummaryDatatableComponent;
