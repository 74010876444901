import React, { useEffect, useState } from 'react'

import DateUTCFormat from '../../utils/DateUTCFormat';
import SummaryDatatableComponent from './SummanyDatatableComponent';
import DetailsDatatableComponent from './DetailsDatatableComponent';

const DiscountsComponent = (props) => {
    const [filterDetails, setFilterDetails] = useState(null);

    useEffect(() => {

        if(filterDetails){
            const summaryBtn = document.getElementById("nav-summary-tab");
            const detailsBtn = document.getElementById("nav-details-tab");
            detailsBtn.disabled = true;
            summaryBtn.click();
            setFilterDetails(null);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.filters]);

    const handleSelectDetails = (date) => {
        const btn = document.getElementById("nav-details-tab");
        const branch_id = props?.filters?.branch_id;
        const start_date = DateUTCFormat(date, 'YYYY-MM-DDT00:00:00');
        const end_date = DateUTCFormat(date, 'YYYY-MM-DDT23:59:59');
        setFilterDetails({branch_id, start_date, end_date})
        btn.disabled = false;
        btn.click();
    }

    const resumeTitle = `Detalle: ${DateUTCFormat(filterDetails?.start_date, 'DD/MMMM/YYYY HH:mm:ss')} a ${DateUTCFormat(filterDetails?.end_date, 'DD/MMMM/YYYY HH:mm:ss')}`;

    const detailsTitle = filterDetails ? resumeTitle : "";

    return (
        <div className='container-full'>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button className="nav-link active" id="nav-summary-tab" data-bs-toggle="tab" data-bs-target="#nav-summary" type="button" role="tab" aria-controls="nav-summary" aria-selected="true">Resumen</button>
                <button className="nav-link" id="nav-details-tab" data-bs-toggle="tab" data-bs-target="#nav-details" type="button" role="tab" aria-controls="nav-details" aria-selected="false" disabled={true}>{detailsTitle}</button>
            </div>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-summary" role="tabpanel" aria-labelledby="nav-summary-tab" tabIndex="0">
                    <SummaryDatatableComponent {...props} handleSelectDetails={handleSelectDetails}/>
                </div>
                <div className="tab-pane fade" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab" tabIndex="0">
                    <DetailsDatatableComponent filters={filterDetails}  />
                </div>
            </div>
      </div>
    )
}

export default DiscountsComponent;
