import React ,{useEffect, useState} from 'react';
import ToolbarComponent from '../../components/shared/ToolbarComponent';
import DateRangeTitleComponent from '../../components/shared/DateRangeTitleComponent';
import DateUTCFormat from '../../utils/DateUTCFormat';
import CancellationsComponent from '../../components/cancellations/CancellationsComponent';
import "./Cancellations.css";

const Cancellations = () => {

  const [filters,setFilters] = useState();

  useEffect(()=> {

    return () => {};

  },[]);


  const handleSyncData = async (values) => {

      setFilters(values);

  };

  const fechaReporte = `${DateUTCFormat(filters?.start_date, 'YYYY-MM-DD HH:mm:ss')} a ${DateUTCFormat(filters?.end_date,'YYYY-MM-DD HH:mm:ss')}`;

  return (
    <main className="cancellations">
       <ToolbarComponent
          title="Cancelaciones"
          handleSyncData={handleSyncData}
        />
      <div className="datetime-range">
        <DateRangeTitleComponent range={fechaReporte}/>
      </div>
      <div className="m-4">
        <CancellationsComponent filters={filters} />
      </div>
    </main>
  )
}

export default Cancellations
