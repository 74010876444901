import React ,{useEffect, useState} from 'react';
import SalesComponent from '../../components/sales/SalesComponent';
import ToolbarComponent from '../../components/shared/ToolbarComponent';
import "./Sales.css";
import DateRangeTitleComponent from '../../components/shared/DateRangeTitleComponent';
import DateUTCFormat from '../../utils/DateUTCFormat';

const Sales = () => {

  const [filters,setFilters] = useState();

  useEffect(()=> {

    return () => {};

  },[]);


  const handleSyncData = async (values) => {

      setFilters(values);

  };

  const fechaReporte = `${DateUTCFormat(filters?.start_date, 'YYYY-MM-DD HH:mm:ss')} a ${DateUTCFormat(filters?.end_date,'YYYY-MM-DD HH:mm:ss')}`;

  return (
    <main className="sales">
       <ToolbarComponent
          title="Ventas"
          handleSyncData={handleSyncData}
        />
      <div className="datetime-range">
        <DateRangeTitleComponent range={fechaReporte}/>
      </div>
      <div className="m-4">
        <SalesComponent filters={filters} />
      </div>
    </main>
  )
}

export default Sales
