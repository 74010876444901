import React, { useEffect, useState } from 'react'
import useToken from '../../hooks/useToken';
import DataTable, {createTheme} from "react-data-table-component";
import PaginationComponent from '../shared/PaginationComponent';
import ExportCSVComponent from '../shared/ExportCSVComponent';
import DownloadCSV from '../../utils/DownloadCSV';
import DateUTCFormat from '../../utils/DateUTCFormat';
import ToCurrencyMXN from '../../utils/ToCurrencyMXN';
import ToastError from '../../utils/ToastError';
import { solarizedTheme } from '../shared/SolarizedTheme';
import { customStyles } from '../shared/CustomStyles';
import { getCancellationsDetails } from '../../services/cancellations/CancellationService';

const DetailsDatatableComponent = ({filters}) => {
    const [data, setData] = useState([{
        id: 1,
        cancellation_date: new Date(),
        table_name: "Mesa Cancelada",
        total_amount: 116.00,
        authorized_name: "Admin",
        reason: "Pruebas Sistemas",
        product_name: "Tostada"
    }]);
    const token = useToken();

    useEffect(()=> {

        if(token && filters){

            getCancellationsDetails(token, filters).then((response) => {
                if(response.status === 200){
                    setData(response.data.data);
                }
            }).catch(()=> {
                ToastError("Error al consultar la información.")
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filters])

    createTheme('solarized',solarizedTheme, 'dark');

    const columns = [
      {
        name: 'Fecha',
        selector: row => row.cancellation_date,
        format: row => DateUTCFormat(row.cancellation_date, 'DD/MMMM/YYYY hh:mm:ss a'),
        sortable: true,
      },
      {
        name: 'Mesa',
        selector: row => row.table_name,
        sortable: true,
      },
      {
        name: 'Costo',
        selector: row => row.cancellation_amount,
        format: row => ToCurrencyMXN(row.cancellation_amount?.toFixed(2)),
        sortable: true,
      },
      {
        name: 'Autorizo',
        selector: row => row.authorization_user_name,
        sortable: true,
      },
      {
        name: 'Motivo',
        selector: row => row.reason,
        sortable: true,
      },
      {
        name: 'Producto',
        selector: row => row.product_name,
        sortable: true,
      }
    ];


    const actionsMemo = React.useMemo(() => <ExportCSVComponent onExport={() => DownloadCSV(data)} disabled={!data.length > 0} />, [data]);

    return (
        <div className='container-full'>
            <div className="border border-top-0 rounded-bottom table-container">
                <DataTable
                columns={columns}
                data={data}
                theme="solarized"
                customStyles={customStyles}
                actions={actionsMemo}
                pagination
                paginationComponent={PaginationComponent}
                />
          </div>
      </div>
    )
}

export default DetailsDatatableComponent;
