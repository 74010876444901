import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "./pages/shared/navbar/Navbar";
import Footer from "./pages/shared/footer/Footer";
import "./App.css";
import { useEffect } from "react";
import ToastInfo from "./utils/ToastInfo";
import useToken from "./hooks/useToken";

const App = () => {
  const navigate = useNavigate();
  const token = useToken();
  const inactivityTime = 10 * 60 * 1000;
  let inactivityTimer;

  const closeSession = () => {

    ToastInfo("Cerrando Sesión");
    sessionStorage.clear();
    navigate("/login");

  }

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(closeSession, inactivityTime);
  };

  useEffect(()=> {

    if(token){

      resetInactivityTimer();

      window.addEventListener("mousemove", resetInactivityTimer);
      window.addEventListener("mousedown", resetInactivityTimer);
      window.addEventListener("keydown", resetInactivityTimer);

    }

    return () => {
      window.addEventListener("mousemove", resetInactivityTimer);
      window.addEventListener("mousedown", resetInactivityTimer);
      window.addEventListener("keydown", resetInactivityTimer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  return (
    <div className="overflow-auto">
      <Navbar />
      <Outlet />
      <Footer/>
    </div>
  );
}

export default App;
