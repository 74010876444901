import React, { useEffect, useState } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { solarizedTheme } from '../shared/SolarizedTheme';
import { customStyles } from '../shared/CustomStyles';
import ExportCSVComponent from '../shared/ExportCSVComponent';
import DownloadCSV from '../../utils/DownloadCSV';
import PaginationComponent from '../shared/PaginationComponent';
import { getSalesProducts } from '../../services/sales/SalesService';
import useToken from '../../hooks/useToken';
import ToastError from '../../utils/ToastError';

const ProductsDatatableComponent = ({filters}) => {
  const [state, setState] = useState({products: true, promotions: true, modifiers: true});
  const [data, setData] = useState([]);
  const token = useToken();

  useEffect(()=> {

    if(filters && token){
      getSalesProducts(token, {...filters, ...state}).then((response)=> {
        if(response.status === 200) {
          setData(response.data.data);
        }
      }).catch(()=> {
        ToastError("Error al consultar información");
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters, state])

  createTheme('solarized',solarizedTheme, 'dark');

  const columns = [
    {
      name: 'Producto',
      selector: row => row.product_name,
      sortable: true,
    },
    {
      name: 'Categoria',
      selector: row => row.category_name,
      sortable: true,
    },
    {
      name: 'Cantidad',
      selector: row => row.quantity,
      format: row => row.quantity.toFixed(2),
      sortable: true,
    },
    {
      name: 'Precio Actual',
      selector: row => row.price,
      format: row => row.price.toFixed(2),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => row.total,
      format: row => row.total.toFixed(2),
      sortable: true,
    }
  ];

  const handleChange = ({target}) => {
    setState({...state, [target.name]: target.checked});
  }

  const actionsMemo = React.useMemo(() =>
      <div className='d-flex flex-row gap-3'>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="products"
            name="products"
            checked={state.products}
            onChange={handleChange}
            />
          <label className="form-check-label fs-5" htmlFor="products">
            Productos
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="promotions"
            name="promotions"
            checked={state.promotions}
            onChange={handleChange}
            />
          <label className="form-check-label fs-5" htmlFor="promotions">
            Promociones
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="modifiers"
            name="modifiers"
            checked={state.modifiers}
            onChange={handleChange}
            />
          <label className="form-check-label fs-5" htmlFor="modifiers">
            Variantes
          </label>
        </div>
        <ExportCSVComponent onExport={() => DownloadCSV(data)} disabled={!data.length > 0} />
      </div>

  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [state, data]);

  return (
    <div className="border rounded table-container">
      <DataTable
        columns={columns}
        data={data}
        theme="solarized"
        customStyles={customStyles}
        actions={actionsMemo}
        pagination
        paginationComponent={PaginationComponent}
        />
    </div>
  )
}

export default ProductsDatatableComponent
