import { toast } from 'react-toastify';

const ToastInfo = (message) => {

    toast(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        style: {
          background: "#628ee4",
          color: "#ffffff"
        },
        progressStyle: {
          background: 'linear-gradient(to right, #ffffff, #628ee4)'
        }
      });
}

export default ToastInfo
